<template>
    <div class="container mx-auto py-8">
        <h2 class="text-2xl font-bold mb-4">Our Collections</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <!-- Collection Card -->
            <div v-for="collection in collections" :key="collection.id"
                class="relative rounded-lg overflow-hidden shadow-md hover:shadow-lg transform transition duration-300 hover:scale-105 group">
                <!-- Collection Image -->
                <img :src="getImagePath(collection.image)" alt="Collection Image"
                    class="w-full h-85 object-cover transition duration-300 ease-in-out hover:brightness-90" />

                <!-- Overlay Text Section with dynamic background color -->
                <div :class="collection.bgColor"
                    class="absolute h-44 bottom-0 left-0 right-0 p-6 text-white rounded-t-lg transition-opacity duration-300 opacity-100 group-hover:opacity-0">
                    <h3 class="text-3xl font-bold">{{ collection.title }}</h3>
                    <p class="text-xl">{{ collection.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            collections: [
                {
                    id: 1,
                    title: 'Gold Products',
                    description:
                        'Bikin ruangan lebih outstanding dan mewah dengan tambahan dekorasi produk berwarna gold dari KJ Perabot.',
                    image: '1.webp',
                    bgColor: 'bg-blue-500',
                },
                {
                    id: 2,
                    title: 'Transparent Products',
                    description:
                        'Produk transparan yang estetik, mulai dari tempat bumbu, tempat sendok, storage serbaguna, piring, dan teko.',
                    image: '2.webp',
                    bgColor: 'bg-brown-500', // Adjust the brown color class
                },
                {
                    id: 3,
                    title: 'Aesthetic Cleaning Tools',
                    description:
                        'KJ Perabot menyediakan aneka perabot dan alat kebersihan serbaguna dengan desain yang minimalis dan estetik.',
                    image: '3.webp',
                    bgColor: 'bg-green-600',
                },
                // Add more collection items as needed
            ],
        };
    },
    methods: {
        getImagePath(image) {
            // Update the path to match the actual location of your images
            try {
                return require(`../assets/collection/${image}`);
            } catch (error) {
                console.error(`Image not found: ${image}`);
                return ''; // Return an empty string or a placeholder image path
            }
        },
    },
};
</script>

<style scoped>
/* Additional styles if needed */
.bg-blue-500 {
    background-color: #6b7cdb;
}

.bg-brown-500 {
    background-color: #a4794c;
}

.bg-green-600 {
    background-color: #3b7942;
}
</style>