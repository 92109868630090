<template>
  <header class="fixed top-0 left-0 right-0 z-50 bg-white shadow-md w-full">
    <!-- Top Bar -->
    <div class="bg-blue-900 py-1 text-center text-sm text-white w-full">
      Perabot Terlengkap Hanya di KJ Perabot
    </div>

    <!-- Main Navbar -->
    <div class="w-full flex items-center justify-between py-2 px-4 lg:px-8 shadow-md">
      <!-- Logo and Search Container -->
      <div class="flex flex-grow items-center justify-between lg:justify-center space-x-4">
        <!-- Logo Container -->
        <div class="flex items-center relative lg:-left-[160px]">
          <button @click="toggleMenu" class="lg:hidden text-gray-900 mr-2">
            <i class="fas fa-bars"></i>
          </button>
          <img src="../assets/logo.png" alt="KJ Perabot Logo" class="h-24 w-auto mr-2" />
        </div>
        <!-- Search Bar for Desktop -->
        <div class="hidden lg:flex flex-grow max-w-xl">
          <div class="relative w-full">
            <input
              type="text"
              placeholder="What are you looking for?"
              class="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none"
              aria-label="Search"
            />
            <button class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" aria-label="Search">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Icons and Login/Register for Desktop -->
      <div class="hidden lg:flex items-center space-x-4 text-gray-900">
        <button class="text-gray-900" aria-label="Refresh">
          <i class="fas fa-sync-alt"></i>
        </button>
        <button class="text-gray-900" aria-label="Shopping cart">
          <i class="fas fa-shopping-cart"></i>
        </button>
        <div class="w-px h-6 bg-gray-300"></div>
        <button class="text-gray-900" aria-label="User">
          <i class="fas fa-user"></i>
        </button>
        <a href="#" class="text-gray-900">Login/Register</a>
      </div>
    </div>

    <!-- Mobile Menu -->
    <div v-if="menuOpen" class="lg:hidden fixed inset-0 bg-white z-40 p-4 overflow-auto">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">Menu</h2>
        <button @click="toggleMenu" class="text-gray-700">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <!-- Tabs for Menu and Account -->
      <div class="flex mb-4">
        <button
          @click="setActiveTab('menu')"
          :class="{'border-b-2 border-blue-700 text-blue-700': activeTab === 'menu'}"
          class="flex-1 py-2 text-center"
        >
          Menu
        </button>
        <button
          @click="setActiveTab('account')"
          :class="{'border-b-2 border-blue-700 text-blue-700': activeTab === 'account'}"
          class="flex-1 py-2 text-center"
        >
          Account
        </button>
      </div>

      <!-- Menu Items -->
      <div v-if="activeTab === 'menu'" class="space-y-4">
        <a href="#" class="block text-gray-700">All Categories</a>
        <a href="#" class="block text-gray-700">Product</a>
        <a href="#" class="block text-gray-700">New Arrivals</a>
        <a href="#" class="block text-gray-700">Sale</a>
      </div>

      <!-- Account Items -->
      <div v-if="activeTab === 'account'" class="space-y-4">
        <a href="#" class="block text-gray-700">Login</a>
        <a href="#" class="block text-gray-700">Register</a>
        <a href="#" class="block text-gray-700">Compare Products</a>
        <a href="#" class="block text-gray-700">Track Order</a>
      </div>
    </div>

    <!-- Desktop Menu with Hoverable Submenu -->
    <nav class="hidden lg:flex items-center justify-center space-x-12 bg-white text-gray-800 py-2 shadow w-full px-4">
      <!-- All Category with Hoverable Submenu -->
      <div class="relative group -left-[420px]">
        <a href="#" class="hover:text-blue-700 flex items-center" aria-haspopup="true">
          All Categories <i class="fas fa-caret-down ml-1"></i>
        </a>
        <!-- Main Menu -->
        <div class="absolute -left-[430px] mt-2 bg-white border rounded-lg shadow-lg w-screen hidden group-hover:flex hover:flex">
          <div class="flex w-full">
            <!-- Main Category Column -->
            <ul class="w-[610px] border-r py-4">
              <li class="relative group px-4 py-2 left-[410px]">
                <a href="#" class="block text-blue-700">Home Kitchen</a>
                <!-- Submenu for Home Kitchen -->
                <div class="absolute top-0 mt-0 submenu">
                  <ul>
                    <li class="hover:bg-gray-100 px-4 py-2">
                      <a href="#" class="block text-gray-800">Botol Minum </a>
                    </li>
                    <li class="hover:bg-gray-100 px-4 py-2">
                      <a href="#" class="block text-gray-800">Casserol </a>
                    </li>
                    <li class="hover:bg-gray-100 px-4 py-2">
                      <a href="#" class="block text-gray-800">Coaster </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="relative group px-4 py-2 left-[410px]">
                <a href="#" class="block text-gray-800">Home Appliances</a>
              </li>
              <li class="relative group px-4 py-2 left-[410px]">
                <a href="#" class="block text-gray-800">Home And Living</a>
              </li>
              <li class="relative group px-4 py-2 left-[410px]">
                <a href="#" class="block text-gray-800">Cleaning</a>
              </li>
              <li class="relative group px-4 py-2 left-[410px]">
                <a href="#" class="block text-gray-800">Bathroom And Sanitary</a>
              </li>
              <li class="relative group px-4 py-2 left-[410px]">
                <a href="#" class="block text-gray-800">Gardening</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Other Menu Items -->
      <a href="#" class="hover:text-blue-700 relative -left-[420px]">Products</a>
      <a href="#" class="hover:text-blue-700 relative -left-[420px]">New Arrivals</a>
      <a href="#" class="hover:text-blue-700 relative -left-[420px]">Sales</a>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      activeTab: 'menu', // Active tab for the mobile menu
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped>
/* Additional styles for fine-tuning the appearance */
.header {
  font-size: 14px; /* Adjust font size if needed */
}

/* Hide all submenus by default */
.submenu {
  display: none;
  position: absolute; /* Positioning submenu absolutely relative to its parent */
  top: 0; /* Align with the top of the parent */
  left: 200px; /* Position submenu to the right of the parent */
  z-index: 50; /* Ensure it appears above other content */
}

/* Show the submenu only when hovering over its parent menu item */
.group:hover > .submenu {
  display: block;
}

/* Show the main submenu when hovering over the parent container */
.group:hover > .group-hover\\:flex,
.group:hover > div:hover {
  display: flex;
}

/* Keep the main menu visible while hovering over either the button or the menu */
.group:hover > div {
  display: flex;
}

/* Optional: Add a light blue background to the main menu item on hover */
.group:hover > .hover\\:bg-blue-100 {
  background-color: #ebf8ff;
}
</style>
