<template>
  <footer class="bg-blue-50 py-8">
    <!-- Stay in Touch Section -->
    <div class="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-8 py-4">
      <div class="text-center md:text-left mb-6 md:mb-0">
        <h2 class="text-xl font-semibold mb-2">Let’s stay in touch!</h2>
        <p class="text-sm text-gray-600">
          Join our exclusive mailing list for latest promo updates and insights.
        </p>
      </div>
      <!-- Subscription Form -->
      <div class="flex items-center space-x-4">
        <input
          type="email"
          placeholder="Enter your email address"
          class="px-4 py-2 rounded-full border border-gray-300 focus:outline-none"
        />
        <button class="bg-blue-700 text-white px-6 py-2 rounded-full hover:bg-blue-800">Subscribe</button>
      </div>
    </div>

    <!-- Main Footer Content -->
    <div class="container mx-auto flex flex-col md:flex-row justify-between items-start px-4 md:px-8 py-8">
      <!-- Logo and Address -->
      <div class="flex flex-col items-center md:items-start mb-8 md:mb-0">
        <img src="../assets/logo.png" alt="KJ Perabot Logo" class="h-24 w-auto mb-4" />
        <p class="text-sm text-gray-600 text-center md:text-left">
          Lorem ipsum odor amet, consectetuer adipiscing elit. Ornare euismod
        </p>
      </div>

      <!-- Store Links -->
      <div class="mb-8 md:mb-0 text-center md:text-left">
        <h3 class="text-lg font-semibold mb-4">Store</h3>
        <ul>
          <li><a href="#" class="text-gray-700 hover:text-blue-500">Sign In</a></li>
          <li><a href="#" class="text-gray-700 hover:text-blue-500">Tracking Order</a></li>
        </ul>
      </div>

      <!-- About Links -->
      <div class="mb-8 md:mb-0 text-center md:text-left">
        <h3 class="text-lg font-semibold mb-4">About</h3>
        <ul>
          <li><a href="#" class="text-gray-700 hover:text-blue-500">Story</a></li>
          <li><a href="#" class="text-gray-700 hover:text-blue-500">Contact Us</a></li>
        </ul>
      </div>

      <!-- Follow Us -->
      <div class="text-center md:text-left">
        <h3 class="text-lg font-semibold mb-4">Follow Us</h3>
        <div class="flex justify-center md:justify-start space-x-4">
          <a href="#" class="text-gray-700 hover:text-blue-500"><i class="fab fa-instagram"></i></a>
          <a href="#" class="text-gray-700 hover:text-blue-500"><i class="fab fa-facebook"></i></a>
          <a href="#" class="text-gray-700 hover:text-blue-500"><i class="fab fa-twitter"></i></a>
          <a href="#" class="text-gray-700 hover:text-blue-500"><i class="fab fa-tiktok"></i></a>
        </div>
      </div>
    </div>

    <!-- Copyright Section -->
    <div class="bg-blue-50 py-4">
      <p class="text-center text-sm text-gray-500">
        © 2024 palapaacc.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
</style>
