<template>
  <div class="container mx-auto px-4 py-12 max-w-8xl h-auto lg:h-screen lg:ml-60 ml-0">
    <h2 class="text-2xl font-bold mb-2">Ideas and Inspiration</h2>
    <p class="text-gray-600 mb-4">
      Lengkapi kebutuhan rumahmu dengan furnitur multifungsi terbaik dari KJ Perabot.
    </p>
    <div class="flex flex-col lg:flex-row gap-6 h-auto lg:h-90">
      <!-- Main Idea Image -->
      <div class="w-full lg:w-1/2">
        <img
          :src="getImagePath(ideas[0].image)"
          alt="Inspiration Image"
          class="w-full h-48 lg:h-full object-cover rounded-lg"
        />
      </div>
      <!-- Smaller Idea/Product Cards -->
      <div class="flex-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div
          v-for="product in products"
          :key="product.id"
          class="border rounded-lg shadow-sm hover:shadow-md p-4 relative"
        >
          <img :src="getImagePath(product.image)" alt="Product Image" class="w-full h-36 lg:h-48 object-cover rounded mb-4 lg:mb-8" />
          <h3 class="text-md font-semibold mb-2 lg:mb-6">{{ product.name }}</h3>
          <p class="text-xs text-gray-500 mb-4 lg:mb-20">KJ PERABOT</p>
          <!-- Rating Stars -->
          <div class="flex items-center mb-2 lg:mb-4">
            <i class="fas fa-star text-gray-300" v-for="n in 5" :key="n"></i>
          </div>
          <!-- Price Details -->
          <div class="flex flex-col mb-2 lg:mb-4">
            <p class="text-lg font-bold text-gray-800">{{ product.currentPrice }}</p>
            <div class="flex items-center text-xs text-gray-500">
              <span v-if="product.oldPrice" class="line-through mr-2">{{ product.oldPrice }}</span>
              <span v-if="product.discount" class="text-red-500">{{ product.discount }}</span>
            </div>
          </div>
          <!-- Action Buttons -->
          <div class="flex items-center justify-between mt-4">
            <button
              class="flex items-center justify-center px-4 py-2 bg-blue-700 text-white text-sm font-semibold rounded hover:bg-blue-800 transition">
              <i class="fas fa-shopping-cart mr-2"></i> Add to Cart
            </button>
            <div class="flex items-center space-x-2">
              <button class="text-gray-500 hover:text-blue-700 transition">
                <i class="fas fa-heart"></i>
              </button>
              <button class="text-gray-500 hover:text-blue-700 transition">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ideas: [
        {
          id: 1,
          title: 'Cozy Living Room Setup',
          description: 'Tips for a cozy living room setup.',
          image: '1.webp',
        },
        // Add more ideas as needed
      ],
      products: [
        {
          id: 1,
          name: 'HL KERANJANG ENCENG AKASA D18 T18',
          currentPrice: 'IDR 52,000',
          image: '2.webp',
        },
        {
          id: 2,
          name: 'MGF DAUN JUNTAI SRI GADING RED',
          currentPrice: 'IDR 109,000',
          image: '3.webp',
        },
        // Add more product items as needed
      ],
    };
  },
  methods: {
    getImagePath(image) {
      // Update the path to match the actual location of your images
      try {
        return require(`../assets/idea/${image}`);
      } catch (error) {
        console.error(`Image not found: ${image}`);
        return ''; // Return an empty string or a placeholder image path
      }
    },
  },
};
</script>

<style scoped>
/* Additional styles for mobile responsiveness */
.container {
  max-width: 100%;
  height: auto;
}
</style>
