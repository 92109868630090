<template>
    <div style="width: 100%;" class="relative bg-white py-8 flex justify-center mt-20 lg:mt-40">
      <!-- Swiper Container with Width Adjustment -->
      <swiper
        :autoplay="{ delay: 3000 }"
        loop="true"
        :navigation="true"
        :pagination="{ clickable: true }"
        class="w-full max-w-7xl"
        style="width: 100%;"
      >
        <!-- Slide 1 -->
        <swiper-slide>
          <div class="relative">
            <img src="../assets/banner/1.webp" alt="Promo Image" class="w-full h-auto rounded-lg shadow-md" />
          </div>
        </swiper-slide>
        <!-- Slide 2 -->
        <swiper-slide>
          <div class="relative">
            <img src="../assets/banner/2.webp" alt="Promo Image" class="w-full h-auto rounded-lg shadow-md" />
          </div>
        </swiper-slide>
        <!-- Slide 3 -->
        <swiper-slide>
          <div class="relative">
            <img src="../assets/banner/3.webp" alt="Promo Image" class="w-full h-auto rounded-lg shadow-md" />
          </div>
        </swiper-slide>
        <!-- Add more swiper-slides as needed -->
      </swiper>
    </div>
  </template>
  
  <script>
  import { Swiper, SwiperSlide } from "swiper/vue";
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  
  export default {
    name: "Banner",
    components: {
      Swiper,
      SwiperSlide,
    },
  };
  </script>
  
  <style scoped>
  /* Additional styles for Swiper navigation dots or arrows can be added here */
  </style>
  