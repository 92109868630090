<template>
  <div>
    <Navbar/>
    <Banner />
    <CategorySection />
    <BestSellers />
    <NewArrivals />
    <Collections />
    <IdeasInspiration class="IdeasInspiration" />
    <WhatsNew class="new" />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Banner from './components/Banner.vue';
import CategorySection from './components/CategorySection.vue';
import BestSellers from './components/BestSellers.vue';
import NewArrivals from './components/NewArrivals.vue';
import Collections from './components/Collections.vue';
import IdeasInspiration from './components/IdeasInspiration.vue';
import WhatsNew from './components/WhatsNew.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Navbar,
    Banner,
    CategorySection,
    BestSellers,
    NewArrivals,
    Collections,
    IdeasInspiration,
    WhatsNew,
    Footer,
  }
};
</script>

<style>
</style>
