<template>
  <div class="relative container mx-auto py-8">
    <h2 class="text-2xl font-bold mb-2">Best Sellers</h2>
    <p class="text-gray-600 mb-4">Temukan perabot favorit kamu diantara produk favorit kami</p>
    <div class="flex justify-between items-center mb-4">
      <h3 class="text-lg font-bold"></h3>
      <a href="#" class="text-blue-600 hover:text-blue-800">Lihat Semua &gt;</a>
    </div>

    <!-- Left Arrow Button -->
    <button
      class="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white text-blue-600 rounded-full shadow-md p-2 hover:bg-blue-100 transition z-10"
      @click="scrollLeft"
    >
      <i class="fas fa-chevron-left"></i>
    </button>

    <!-- Horizontal Scroll Wrapper -->
    <div ref="scrollContainer" class="flex overflow-x-auto space-x-6 py-4 px-4 scrollbar-hide">
      <!-- Example Product Card -->
      <div
        v-for="product in products"
        :key="product.id"
        class="min-w-[250px] max-w-[250px] flex-shrink-0 border rounded-lg shadow-sm hover:shadow-lg p-4 relative"
      >
        <div
          class="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full"
          v-if="product.isSale"
        >
          SALE
        </div>
        <img
          :src="getImagePath(product.image)"
          alt="Product Image"
          class="w-full h-40 object-cover rounded"
        />
        <h3 class="mt-2 text-md font-semibold">{{ product.name }}</h3>
        <p class="text-xs text-gray-500 mb-2">KJ PERABOT</p>
        <!-- Rating Stars -->
        <div class="flex items-center mb-2">
          <i class="fas fa-star text-gray-300" v-for="n in 5" :key="n"></i>
        </div>
        <!-- Price Details -->
        <div class="flex flex-col">
          <p class="text-lg font-bold text-gray-800">{{ product.currentPrice }}</p>
          <div class="flex items-center text-xs text-gray-500">
            <span v-if="product.oldPrice" class="line-through mr-2">{{ product.oldPrice }}</span>
            <span v-if="product.discount" class="text-red-500">{{ product.discount }}</span>
          </div>
        </div>
        <!-- Action Buttons -->
        <div class="flex items-center justify-between mt-4">
          <button
            class="flex items-center justify-center px-4 py-2 bg-blue-700 text-white text-sm font-semibold rounded hover:bg-blue-800 transition"
          >
            <i class="fas fa-shopping-cart mr-2"></i> Add to Cart
          </button>
          <div class="flex items-center space-x-2">
            <button class="text-gray-500 hover:text-blue-700 transition">
              <i class="fas fa-heart"></i>
            </button>
            <button class="text-gray-500 hover:text-blue-700 transition">
              <i class="fas fa-sync-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Arrow Button -->
    <button
      class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white text-blue-600 rounded-full shadow-md p-2 hover:bg-blue-100 transition z-10"
      @click="scrollRight"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          id: 1,
          name: 'HL SHOE CABINET EGIO 98X27X116 BROWN',
          currentPrice: 'IDR 999,000',
          oldPrice: 'IDR 1,799,000',
          discount: '44%',
          isSale: true,
          image: '1.webp',
        },
        {
          id: 2,
          name: 'HB WOVEN SQUARE BAG L BLACK',
          currentPrice: 'IDR 135,000',
          oldPrice: '',
          discount: '',
          isSale: false,
          image: '2.webp',
        },
        {
          id: 3,
          name: 'HL SHOE CABINET EGIO 98X27X116 BROWN',
          currentPrice: 'IDR 999,000',
          oldPrice: 'IDR 1,799,000',
          discount: '44%',
          isSale: true,
          image: '3.webp',
        },
        {
          id: 4,
          name: 'HB WOVEN SQUARE BAG L BLACK',
          currentPrice: 'IDR 135,000',
          oldPrice: '',
          discount: '',
          isSale: false,
          image: '4.webp',
        },
        {
          id: 5,
          name: 'HL SHOE CABINET EGIO 98X27X116 BROWN',
          currentPrice: 'IDR 999,000',
          oldPrice: 'IDR 1,799,000',
          discount: '44%',
          isSale: true,
          image: '5.webp',
        },
        {
          id: 6,
          name: 'HB WOVEN SQUARE BAG L BLACK',
          currentPrice: 'IDR 135,000',
          oldPrice: '',
          discount: '',
          isSale: false,
          image: '6.webp',
        },
        // Add more products as needed
      ],
    };
  },
  methods: {
    getImagePath(image) {
      try {
        return require(`../assets/best-seller/${image}`);
      } catch (error) {
        console.error(`Image not found: ${image}`);
        return ''; // Return an empty string or a placeholder image path
      }
    },
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({ left: -250, behavior: 'smooth' });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({ left: 250, behavior: 'smooth' });
    },
  }
};
</script>

<style scoped>
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
