<template>
  <div class="container mx-auto py-8">
    <div class="flex justify-center space-x-12 overflow-x-auto px-4 py-4 scrollbar-hide">
      <div
        v-for="category in categories"
        :key="category.id"
        class="flex flex-col items-center text-center group cursor-pointer"
      >
        <!-- Category Image with Hover Effect -->
        <div class="relative">
          <img
            :src="getImagePath(category.image)"
            alt="Category Image"
            class="w-50 h-50 rounded-full object-cover mb-2 transform transition-transform duration-300 ease-in-out group-hover:scale-105"
          />
          <!-- Optional Tooltip or Label -->
          <div
            class="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-800 text-white text-xs px-2 py-1 rounded shadow-lg"
          >
            {{ category.name }}
          </div>
        </div>
        <!-- Category Name -->
        <p class="text-sm font-medium text-gray-700 group-hover:text-blue-600">
          {{ category.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [
        { id: 1, name: 'Home Kitchen', image: '1.webp' },
        { id: 2, name: 'Home Living', image: '2.webp' },
        { id: 3, name: 'Home Cleaning', image: '3.webp' },
        { id: 4, name: 'Home Decor', image: '4.webp' },
        { id: 5, name: 'Home Appliances', image: '5.webp' },
        { id: 6, name: 'Gardening', image: '6.webp' },
        // Add more categories as needed
      ]
    };
  },
  methods: {
    getImagePath(image) {
      return require(`../assets/categ/${image}`);
    }
  }
};
</script>

<style scoped>
/* Hide default scrollbar for a smoother horizontal scrolling effect */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
