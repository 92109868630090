<template>
  <div class="relative container mx-auto py-8">
    <h2 class="text-2xl font-bold mb-2">New Arrivals</h2>
    <p class="text-gray-600 mb-4">Temukan produk baru yang tersedia di KJ Perabot</p>
    
    <!-- Left Arrow Button -->
    <button
      class="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white text-blue-600 rounded-full shadow-md p-2 hover:bg-blue-100 transition z-10"
      @click="scrollLeft"
    >
      <i class="fas fa-chevron-left"></i>
    </button>

    <!-- Horizontal Scroll Wrapper -->
    <div ref="scrollContainer" class="flex overflow-x-auto space-x-6 py-4 px-4 scrollbar-hide">
      <!-- Product Card -->
      <div
        v-for="item in newArrivals"
        :key="item.id"
        class="min-w-[250px] max-w-[250px] flex-shrink-0 border rounded-lg shadow-sm hover:shadow-lg p-4 relative"
      >
        <img
          :src="getImagePath(item.image)"
          alt="New Arrival Image"
          class="w-full h-40 object-cover rounded"
        />
        <h3 class="mt-2 text-md font-semibold">{{ item.name }}</h3>
        <p class="text-xs text-gray-500 mb-2">KJ PERABOT</p>
        <div class="flex flex-col">
          <p class="text-lg font-bold text-gray-800">{{ item.price }}</p>
        </div>
        <!-- Action Buttons -->
        <div class="flex items-center justify-between mt-4">
          <button
            class="flex items-center justify-center px-4 py-2 bg-blue-600 text-white text-sm font-semibold rounded hover:bg-blue-800 transition"
          >
            <i class="fas fa-shopping-cart mr-2"></i> Add to Cart
          </button>
          <!-- Additional Icons -->
          <div class="flex items-center space-x-2">
            <button class="flex items-center justify-center w-8 h-8 border rounded-full text-gray-500 hover:text-blue-700 transition">
              <i class="fas fa-heart"></i>
            </button>
            <button class="flex items-center justify-center w-8 h-8 border rounded-full text-gray-500 hover:text-blue-700 transition">
              <i class="fas fa-sync-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Arrow Button -->
    <button
      class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white text-blue-600 rounded-full shadow-md p-2 hover:bg-blue-100 transition z-10"
      @click="scrollRight"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newArrivals: [
        {
          id: 1,
          name: 'KJP-1 Mangkok Tutup JWV Glass',
          price: 'IDR 38,000',
          image: '1.webp',
        },
        {
          id: 2,
          name: 'KJP-1 Mangkok Tutup JWV Glass',
          price: 'IDR 38,000',
          image: '2.webp',
        },
        {
          id: 3,
          name: 'KJP-1 Mangkok Tutup JWV Glass',
          price: 'IDR 38,000',
          image: '3.webp',
        },
        {
          id: 4,
          name: 'KJP-1 Mangkok Tutup JWV Glass',
          price: 'IDR 38,000',
          image: '4.webp',
        },
        {
          id: 5,
          name: 'KJP-1 Mangkok Tutup JWV Glass',
          price: 'IDR 38,000',
          image: '5.webp',
        },
        {
          id: 6,
          name: 'KJP-1 Mangkok Tutup JWV Glass',
          price: 'IDR 38,000',
          image: '6.webp',
        },
        // Add more new arrival products as needed
      ],
    };
  },
  methods: {
    getImagePath(image) {
      try {
        return require(`../assets/new-arrivals/${image}`);
      } catch (error) {
        console.error(`Image not found: ${image}`);
        return ''; // Return an empty string or a placeholder image path
      }
    },
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({ left: -250, behavior: 'smooth' });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({ left: 250, behavior: 'smooth' });
    },
  }
};
</script>

<style scoped>
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
