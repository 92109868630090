<template>
  <div class="container mx-auto py-8">
    <h2 class="text-2xl font-bold mb-4">What's New in KJ Perabot</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div
        v-for="item in whatsNew"
        :key="item.id"
        class="rounded overflow-hidden shadow-lg transform hover:scale-105 transition duration-300"
      >
        <img
          :src="item.image"
          :alt="item.title"
          class="w-full h-48 object-cover"
          loading="lazy"
        />
        <div class="p-4">
          <h3 class="font-semibold text-lg">{{ item.title }}</h3>
          <p class="text-sm text-gray-600 mt-2">{{ formatDate(item.date) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  data() {
    return {
      whatsNew: [
        {
          id: 1,
          title: 'Offline Store KJ Perabot',
          date: '2024-08-10',
          image: require('../assets/new/1.webp'), // Correct path to images
        },
        {
          id: 2,
          title: 'Toples Aesthetic Terbaru',
          date: '2024-04-17',
          image: require('../assets/new/2.webp'),
        },
        {
          id: 3,
          title: 'Toples Trophy Gold Aesthetic Terbaru',
          date: '2024-04-17',
          image: require('../assets/new/3.webp'),
        },
        {
          id: 4,
          title: 'Toples Kaca Aesthetic Terbaru',
          date: '2024-04-17',
          image: require('../assets/new/4.webp'),
        },
        // Add more items as needed
      ],
    };
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'MMM d, yyyy');
    },
  },
};
</script>

<style scoped>
.shadow-lg {
  transition: box-shadow 0.3s ease;
}
</style>
